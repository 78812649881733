import React from "react"
import { Box, ResponsiveContext } from "grommet"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Landing from "../pages/Landing"
import SEO from "../components/seo"
import Wrapper from "../components/Container/Wrapper"
import { P } from "../components/Typography"
import { theme } from "../styles/theme"
import TriangleSection from "../components/Elements/TriangleSection"
import TriangleBottom from "../components/Elements/TriangleBottom"
import { Grommet } from "grommet-icons"

const Disclaimer = ({ data }) => {
  const DisclaimerPoint = ({ point }) => (
    <Box direction="row">
      <Box width="32px" pad={{ top: "8px" }}>
        <Grommet color="paragraphLight" size="12px" />
      </Box>
      <P margin={{ top: "none" }}>{`disclaimer:${point}`}</P>
    </Box>
  )

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Layout>
            <SEO title="Disclaimer" />
            <Box
              align="center"
              background={theme.global.gradients.purpleBlueDark}
              style={{
                position: "relative",
                marginTop: "75px",
                zIndex: 0,
              }}
            >
              <Box
                style={{
                  background:
                    "linear-gradient(180deg, rgba(33,30,50,1) 0%, rgba(255,255,255,0) 100%)",
                  position: "absolute",
                  height: "40%",
                  width: "100%",
                  zIndex: 2,
                }}
              />
              <Img
                fluid={data.file.childImageSharp.fluid}
                style={{
                  opacity: "0.2",
                  background: "#393272",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                }}
              />

              <Box
                background={theme.global.gradients.purpleBlueDark}
                style={{
                  background: "#393272",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  mixBlendMode: "lighten",
                }}
              />
              <Box width="xlarge" direction="column" style={{ zIndex: 2 }}>
                <Landing height="small" section="disclaimer" />
              </Box>

              <TriangleBottom size={size} />
              <TriangleSection size={size} />
              <TriangleSection size={size} variant="medium" />
            </Box>

            <Box background="white" align="center">
              <Box width="xlarge" direction="column">
                <Wrapper>
                  <P margin={{ top: "none" }}>disclaimer:section1Paragraph</P>
                  <P bold>disclaimer:section2Paragraph</P>

                  <DisclaimerPoint point="point1" />
                  <DisclaimerPoint point="point2" />
                  <DisclaimerPoint point="point3" />
                  <DisclaimerPoint point="point4" />
                  <DisclaimerPoint point="point5" />
                  <DisclaimerPoint point="point6" />
                  <DisclaimerPoint point="point7" />
                  <DisclaimerPoint point="point8" />
                </Wrapper>
              </Box>
            </Box>
          </Layout>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Disclaimer

export const query = graphql`
  query DisclaimerImage {
    file(relativePath: { eq: "finePrint.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
